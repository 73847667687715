import React from "react";
import ProfilePic from "../Assets/celec-image.png";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper" id="container-testimonial">
      <div className="work-section-top">
        <p className="primary-subheading">Testimonios</p>
        <h1 className="primary-heading">Que dicen nuestros clientes</h1>
        <p className="primary-text">
          Nosotros confiamos en Berliet por el gran servicio y la calidad del trabajo. 
        </p>
      </div>
      <div className="testimonial-section-bottom">
        <img src={ProfilePic} alt="" />
        <p>
        "En la corporacion CELEC EP confiamos en Berliet por el gran servicio y la calidad del trabajo. Ellos siempre estan dispuestos a ayudarnos lo mas pronto posible y el trabajo es garantizado.<br/>Gracias Berliet."
        </p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>John Doe</h2>
        <sub>Gerente de Operaciones</sub>
      </div>
    </div>
  );
};

export default Testimonial;
