import React, { useRef } from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/berlietworker1.jpg";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = ({ref}) => {
  
  return (
    <div ref={ref} className="about-section-container" id="container-about">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" width={600} />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">Conozcanos</p>
        <h1 className="primary-heading">
          Profesionales en systemas hidraulicos
        </h1>
        <p className="primary-text">
          Somos un grupo de tecnicos profesionales con la capacidad y experiencia necesaria en la reparacion de todo tipo de systemas oleohidraulicos 
        </p>
        <p className="primary-text">
          Si necesitas ayuda profesional contactanos.  Nuestro objetivo es servir a nuestra clientela con el mejor servicio.
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button">Learn More</button>
          <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
