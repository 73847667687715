import React from "react";
import Logo from "../Assets/qP6N7l01.svg";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import {scrollingTo} from "../Assets/Functions" 

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        
        <div className="footer-section-columns">
          <span>0986560832</span>
          <span>berliet_hidraulicos@hotmail.com</span>
        </div>
        <div className="footer-icons">
          <BsTwitter />
          <SiLinkedin />
          <BsYoutube />
          <FaFacebookF />
        </div>

      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span onClick={(e) => { scrollingTo(e, "container-servicios"); }}>Servicios</span>
          <span onClick={(e) => { scrollingTo(e, "container-about"); }}>Conozcanos</span>
          <span onClick={(e) => { scrollingTo(e, "container-testimonial"); }}>Testimonios</span>
          
        </div>
       
        <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
