import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/berliettmain.jpg";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            Especialistas en Sistemas Hidraulicos
          </h1>
          <p className="primary-text">
            Reparacion, Mantenimiento, Mecanica.  Ofrecemos soluciones a problemas de sistemas hidraulicos.
          </p>
          <p><h1 className="primary-heading">0986560832</h1> <h3 className="secondary-text">berliet_hidraulicos@hotmail.com</h3> <br/></p>
          <button className="secondary-button">
            Contactanos Ahora <FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
