import React from "react";
import RepairSvg from "../Assets/repair-svgrepo-com.svg";
import MechanicSvg from "../Assets/mechanic-with-cap-svgrepo-com.svg";
import PartsSvg from "../Assets/pipes-construction-and-tools-svgrepo-com.svg";

const Work = () => {
  const workInfoData = [
    {
      image: RepairSvg,
      title: "Reparaciones",
      text: "Nuestro equipo tecnico tiene capacidad para reparar una variedad the sistemas complejos",
    },
    {
      image: MechanicSvg,
      title: "Mantenimiento",
      text: "Brindamos servicio de mantenimiento para equipo industrial o maquinaria de construction",
    },
    {
      image: PartsSvg,
      title: "Venta de partes",
      text: "Comercializamos partes para diferentes sistemas hidraulicos como sellos, bombas y todo tipo de partes industriales",
    },
  ];
  return (
    <div className="work-section-wrapper"  id="container-servicios">
      <div className="work-section-top">
        <p className="primary-subheading">Servicios</p>
        <h1 className="primary-heading">Algunos de los servicios que ofrecemos</h1>
        <p className="primary-text">
        Nuestra empresa ofrece servicios de mantenimiento y reparación a sistemas Oleohidráulicos, aplicados a cualquiera área de trabajo como automotriz, vehículos pesados, equipos camioneros, equipos agrícolas, industrias en general.
        
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
